import React, { useState } from "react"
import styles from "./alert-modal.module.scss"
import PropTypes from "prop-types"
import {
  AiOutlineExclamation,
  AiOutlineCheck,
  AiOutlineInfo,
} from "react-icons/ai"
import classNames from "classnames/bind"
import Button from "../button/button"

const AlertModal = ({
  type,
  title,
  content,
  cancelLabel,
  confirmLabel,
  cancelId,
  confirmId,
  handleCancel,
  handleConfirm,
}) => {
  var cx = classNames.bind(styles)
  const styleGroup = cx([type])

  const onCancel = () => {
    if (handleCancel) {
      handleCancel()
    }
  }

  const onConfirm = () => {
    if (handleConfirm) {
      handleConfirm()
    }
  }

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.icon, styleGroup)}>
        {type === "alert" ? <AiOutlineExclamation /> : ""}
        {type === "success" ? <AiOutlineCheck /> : ""}
        {type === "info" ? <AiOutlineInfo /> : ""}
      </div>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.content}>{content}</p>
      <div className={styles.actions}>
        {cancelLabel ? (
          <Button
            id={cancelId}
            label={cancelLabel}
            btnStyle="ghost"
            onClick={onCancel}
          />
        ) : (
          ""
        )}
        {confirmLabel ? (
          <Button id={confirmId} label={confirmLabel} onClick={onConfirm} />
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

AlertModal.defaultProps = {
  type: "alert",
  title: "You have unsaved changes!",
  content: "Are you sure you want to leave this page without saving it?",
  cancelLabel: "Cancel",
  confirmLabel: "Confirm",
  cancelId: "modalCancelButton",
  confirmId: "modalDeleteButton",
}

AlertModal.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelId: PropTypes.string,
  confirmId: PropTypes.string,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
}

export default AlertModal
