import PropTypes from "prop-types"
import React from "react"
import ConfirmNavLink from "../../common/confirm-nav-link/confirm-nav-link"
import Menu from "../menu/menu"
import styles from "./header.module.scss"
import classNames from "classnames"

const Header = ({ siteTitle }) => {
  return (
    <header className={styles.header}>
      <div id="headerLogo" className={classNames(styles.appHeaderLogo, "app-header-logo")}>
        <ConfirmNavLink to="/">{siteTitle}</ConfirmNavLink>
      </div>
      <Menu />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
