import axios from "axios"
import http from "http"
import _ from "lodash"
import React, { useState, useEffect } from "react"
import modal from "../../../../services/modal"
import ModalWindow from "../../modal-window/modal-window"
import AlertModal from "../../alert-modal/alert-modal"
import useForceUpdate from "../../../../hooks/use-force-update"

function ModalWrapper() {
  /** ******************************
   * START ALERT MODAL HANDLING
   ****************************** **/
  const [alertModalOpen, setAlertModalOpen] = useState(
    modal.getAlertModalOpen()
  )
  const onAlertModalClose = () => {
    if (alertModalOpen) {
      modal.setAlertModalOpen(false)
      if (modal.getAlertModalParams().onAlertModalClose) {
        modal.getAlertModalParams().onAlertModalClose()
      }
    }
  }

  const onAlertModalCancel = () => {
    if (modal.getAlertModalParams().cancel) {
      modal.getAlertModalParams().cancel()
    }
    onAlertModalClose()
  }

  const onAlertModalConfirm = () => {
    if (modal.getAlertModalParams().confirm) {
      modal.getAlertModalParams().confirm()
    }
    onAlertModalClose()
  }
  modal.setWrapperForceUpdate(useForceUpdate())

  if (modal.getAlertModalOpen() !== alertModalOpen) {
    console.log(
      "modal.getAlertModalOpen() === " +
        modal.getAlertModalOpen() +
        " alertModalOpen === " +
        alertModalOpen
    )
    setAlertModalOpen(modal.getAlertModalOpen())
  }

  const showAlertModal = () => {
    if (alertModalOpen) {
      return (
        <ModalWindow closeHandler={onAlertModalCancel}>
          <AlertModal
            cancelLabel={modal.getAlertModalParams().cancelLabel}
            confirmLabel={modal.getAlertModalParams().confirmLabel}
            cancelId={modal.getAlertModalParams().cancelId}
            confirmId={modal.getAlertModalParams().confirmId}
            handleCancel={onAlertModalCancel}
            handleConfirm={onAlertModalConfirm}
            title={modal.getAlertModalParams().title}
            content={modal.getAlertModalParams().content}
          />
        </ModalWindow>
      )
    }
  }
  /** ******************************
   * END ALERT MODAL HANDLING
   ****************************** **/

  return <div>{showAlertModal()}</div>
}

ModalWrapper.defaultProps = {}

ModalWrapper.propTypes = {}

export default ModalWrapper
