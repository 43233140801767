import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import styles from "./button.module.scss"
import * as ReactIcon from "react-icons/md"

const Button = ({
  type,
  label,
  btnStyle,
  btnSize,
  fullWidth,
  disableStatus,
  prependIcon,
  appendIcon,
  iconOnly,
  onClick,
  id,
}) => {
  var cx = classNames.bind(styles)
  const styleGroup = cx([
    btnStyle,
    btnSize,
    { fullWidth: fullWidth },
    { disable: disableStatus },
  ])

  let labelText = ''
  if (label) {
    labelText = label.toUpperCase()
  }

  const styleIcon = `styles.${iconOnly}`
  // console.log('styleIcon:', styleIcon)

  return (
    <div className={styles.wrapper}>
      <button
        type={type}
        id={id}
        className={classNames(styleGroup)}
        onClick={onClick}
        disabled={disableStatus}
      >
        {iconOnly ? (
          <div
            className={styles.tilesIcon}
          ></div>
        ) : (
          ""
        )}
        {prependIcon ? (
          <span className={styles.icon}>
            {React.createElement(ReactIcon[prependIcon])}
          </span>
        ) : (
          ""
        )}
        {labelText}
        {appendIcon ? (
          <span className={classNames(styles.icon, styles.appendIcon)}>
            {React.createElement(ReactIcon[appendIcon])}
          </span>
        ) : (
          ""
        )}
      </button>
    </div>
  )
}

Button.defaultProps = {
  type: "button",
  btnStyle: "primary",
  btnSize: "medium",
}

Button.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string, // Enum?
  label: PropTypes.string,
  btnStyle: PropTypes.string, // Enum?
  btnSize: PropTypes.string,
  disableStatus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  prependIcon: PropTypes.string,
  appendIcon: PropTypes.string,
  iconOnly: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default Button
